import { H, Section } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import {
  getSiteIndexPath,
  getSiteIndexLabel,
  getSiteIndexTitle,
} from "../../../shared";
import { useHTMLProcessor } from "../../hooks/htmlProcessor";
import { PageContextProvider } from "../../hooks/pageContext";
import Breadcrumb from "../Breadcrumb";
import Excerpt from "../Excerpt/Excerpt";
import Link from "../Link/Link";

import "./SiteIndex.scss";

const compare = new Intl.Collator("sv").compare;

export default function SiteIndex({
  pages,
  initial,
  initials,
  className,
  ...restProps
}) {
  const { stripHTML } = useHTMLProcessor();

  const allInitials = [
    ...new Set([
      ...initials,
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "å",
      "ä",
      "ö",
    ]),
  ];

  allInitials.sort((a, b) => (!a === !b ? compare(a, b) : !a - !b));

  pages.sort((a, b) => compare(a.title, b.title));

  let currentInitial = initial;

  return (
    <div className={cx("c-site-index", className)} {...restProps}>
      <div className="c-site-index__alphabetical-pages">
        <ul className="c-site-index__alphabetical-list">
          {allInitials.map((initial) => {
            return (
              <li
                className={cx(
                  "c-site-index__alphabetical-item",
                  initial === currentInitial &&
                    "c-site-index__alphabetical-item--active",
                )}
                key={initial}
              >
                <Link
                  disabled={!initials.includes(initial)}
                  to={getSiteIndexPath({ initial })}
                  aria-current={initial === currentInitial && "page"}
                >
                  {getSiteIndexLabel({ initial })}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="c-site-index__results">
        <H className="c-site-index__title">{getSiteIndexTitle({ initial })}</H>
        <ul className="c-site-index__result-list">
          {pages.map((page, index) => {
            return (
              <li className="c-site-index__result-item" key={index}>
                <PageContextProvider
                  value={{
                    page,
                    postTypeName: "page",
                    postId: page.databaseId,
                  }}
                >
                  <Link className="c-site-index__result-title" to={page.url}>
                    {page.title}
                  </Link>
                  <Excerpt
                    className="c-site-index__result-description"
                    text={
                      page.description
                        ? page.description
                        : stripHTML(page.content)
                    }
                  />
                  <Breadcrumb hideRoot={true} className="c-breadcrumb--list" />
                </PageContextProvider>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
