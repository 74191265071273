exports.GATSBY_WORDPRESS_URL = process.env.GATSBY_WORDPRESS_URL;

exports.getSiteIndexPath = ({ initial }) => {
  if (initial) {
    return `/innehall/${initial}`;
  }
  return `/innehall/ovriga-sidor`;
};

exports.getSiteIndexTitle = ({ initial }) => {
  if (initial) {
    return initial.toUpperCase();
  }
  return `Övriga sidor`;
};

exports.getSiteIndexLabel = ({ initial }) => {
  if (initial) {
    return initial.toUpperCase();
  }
  return `#`;
};
