import { H, Section } from "@jfrk/react-heading-levels";
import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import SiteIndex from "../components/SiteIndex/SiteIndex";
import { PageContextProvider } from "../hooks/pageContext";

export default function SiteIndexTemplate({ pageContext }) {
  const { initial, pages, initials } = pageContext;

  return (
    <PageContextProvider
      value={{
        ...pageContext,
      }}
    >
      <Layout hideSearchForm={true}>
        <SEO title="Innehåll A till Ö" />
        <article className="c-article o-margin-top-large">
          <div className="o-grid">
            <div className="o-grid-row">
              <div className="o-grid-block o-grid-block--inherit">
                <H className="c-article__title">Innehåll A till Ö</H>
                <Section>
                  <SiteIndex
                    initial={initial}
                    pages={pages}
                    initials={initials}
                  />
                </Section>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </PageContextProvider>
  );
}
