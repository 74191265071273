import React from "react";

import { usePageContext } from "../../hooks/pageContext";
import usePages from "../../hooks/pages";
import { getFrontPage, getAncestors, getPage } from "../../utils/pageTree";
import { getPostType } from "../../utils/postTypes";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

import Breadcrumb from "./Breadcrumb";

export default function BreadcrumbContainer({
  crumbs: manualCrumbs,
  hideRoot,
  hideCurrent,
  ...restProps
}) {
  const allPages = usePages();
  const crumbs = [getFrontPage(allPages)];
  if (manualCrumbs) {
    crumbs.push(...manualCrumbs);
  } else {
    const pageContext = usePageContext();
    const { postTypeName } = pageContext;
    switch (postTypeName) {
      case "page":
        {
          const {
            page: { id: pageId },
          } = pageContext;
          crumbs.push(
            ...getAncestors(allPages, pageId),
            getPage(allPages, pageId),
          );
        }
        break;
      default: {
        let postType = getPostType(postTypeName);
        if (postType && postType.archive) {
          let {
            labels: { name: label },
            archive: { url },
          } = postType;
          crumbs.push({ label, url });
        }
        let { label, title } = pageContext[postTypeName];
        crumbs.push({ label: label || title });
      }
    }

    if (hideRoot) {
      crumbs.shift();
    }

    if (hideCurrent) {
      crumbs.pop();
    }
  }
  if (!crumbs || crumbs.length === 0) {
    return null;
  }
  return (
    <ErrorBoundary>
      <Breadcrumb crumbs={crumbs} {...restProps} />
    </ErrorBoundary>
  );
}
