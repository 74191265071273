import cx from "classnames";
import React from "react";

import Link from "../Link/Link";

import "./Breadcrumb.scss";

export default function Breadcrumb({ crumbs, className, ...restProps }) {
  return (
    <nav
      aria-label="Brödsmulor"
      className={cx(
        "c-breadcrumb",
        "o-section--full-width",
        "u-hidden-print",
        className,
      )}
      {...restProps}
    >
      <div className="o-container o-margin-small">
        <ol className="c-breadcrumb-list">
          {crumbs.map((crumb, index) => {
            if (!crumb) {
              console.warn("One breadcrumb was null", crumbs);
              return null;
            }
            return (
              <li key={index} className="c-breadcrumb-item">
                <Link
                  to={crumb.url}
                  className="c-breadcrumb-link"
                  aria-current={index === crumbs.length - 1 ? "page" : null}
                >
                  {crumb.label}
                </Link>
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
}
